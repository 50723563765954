import axios from "axios";

// Access the environment variable
const BASE_URL_AXIOS = 'https://spe.orangelaboratory.com/spe/';

axios.defaults.baseURL = BASE_URL_AXIOS;

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;

        const response = await axios.post('refresh', {}, {withCredentials: true});

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['token']}`;

            return axios(error.config);
        }
    }
    refresh = false;
    return error;
});