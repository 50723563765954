import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
  StreetViewPanorama,
} from "@react-google-maps/api";
import Results from "./Results";
import { Button, Form, Stack, Modal } from "react-bootstrap";
import "./Spinner.css";

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://spe.orangelaboratory.com';

const FootPrintMap = () => {
  const [position, setPosition] = useState({ lat: 40.7128, lng: -74.006 });
  const [center, setCenter] = useState({ lat: 40.7128, lng: -74.006 });
  const [heading, setHeading] = useState(0);
  const [pitch, setPitch] = useState(0);
  const [address, setAddress] = useState("");
  const [inputWidth, setInputWidth] = useState(300);
  const [modalShow, setModalShow] = useState(false);
  //const [modalContent, setModalContent] = useState({});
  const panoramaRef = useRef(null);
  const [responseData, setResponseData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [progressText, setProgressText] = useState("");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [streetviewImage, setStreetviewImage] = useState(""); 

  const [userComment, setUserComment] = useState("");
    // Add state for X and Y coordinates
  const [coordinates, setCoordinates] = useState([]);
  const [coordinateHistory, setCoordinateHistory] = useState([]);
  const [rightClickCoordinates, setRightClickCoordinates] = useState([]);
  const [rightClickCoordinateHistory, setRightClickCoordinateHistory] = useState([]);

  const handleUserComment = (comment) => {
    setUserComment(comment);
    console.log(userComment);
  };

  const Popup = ({ text }) => (
    <div className="popup">
      <div className="spinner"></div>
      <p>{text}</p>
    </div>
  );

  /**
   * Handles the analyze button click event.
   * 
   * This function performs the following steps:
   * 1. Shows a popup and sets the progress text to indicate the start of the analysis.
   * 2. Calls the handleApply function to obtain the Street View Panorama.
   * 3. Updates the progress text to indicate that the panorama has been obtained and the footprint location process is starting.
   * 4. Calls the handleFootClick function to locate the footprint.
   * 5. Updates the progress text to indicate that the footprint has been located and the AI engine is launching.
   * 6. Waits for 3 seconds to simulate the AI engine launch.
   * 
   * If an error occurs during any of these steps, it logs the error, updates the progress text to indicate an error, and waits for 6 seconds.
   * Finally, it hides the popup.
   * 
   * @async
   * @function handleAnalyzeClick
   * @returns {Promise<void>} A promise that resolves when the analysis process is complete.
   */
  const handleAnalyzeClick = async () => {
    try {
      setIsPopupVisible(true);
      setProgressText("Starting analysis - Obtaining Street View Panorama...");

      await handleApply();
      setProgressText(
        "Streetview Panorama obtained... Locating Footprint... \n This may take a while..."
      );

      await handleFootClick();
      setProgressText("Footprint located... \n Launching AI Engine...");
      await delay(3000);
    } catch (error) {
      console.error("Error in handleAnalyzeClick:", error);
      setProgressText("Error occurred");
      await delay(6000);
    } finally {
      setIsPopupVisible(false);
    }
  };

  const handleGeocode = () => {
    if (address) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyC9ijkEj5qtZdFD-c0umowBwF4w4IWUE4s`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.results && data.results[0] && data.results[0].geometry) {
            const { lat, lng } = data.results[0].geometry.location;
            const newCenter = { lat, lng };
            setCenter(newCenter);
            setPosition(newCenter);
          } else {
            console.error("Geocoding error: No valid results");
          }
        })
        .catch((error) => {
          console.error("Geocoding error: ", error);
        });
    }
  };

  const handleApply = async () => {
    try {
      const data = {
        latitude: position.lat,
        longitude: position.lng,
        heading: heading,
        pitch: pitch,
        address: address,
      };

      const response = await axios.post(
        "https://spe.orangelaboratory.com/api/",
        data
      );
      console.log("Task triggered:", response);
    } catch (error) {
      console.error("Error triggering task:", error);
    }
  };

  const handleFootClick = async () => {
    try {
      const data = {
        latitude: position.lat,
        longitude: position.lng,
        address: address,
        heading: heading,
        pitch: pitch,
      };
      const response = await axios.post(
        "https://spe.orangelaboratory.com/foot/",
        data
      );
      console.log("Foot button clicked");
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };

  /**
   * Handles the click event for triggering a task with the given position and address data.
   * Sends a POST request to the specified endpoint with the latitude, longitude, address, heading, and pitch.
   * Updates the response data state with the response from the server.
   * Logs an error message if the request fails.
   *
   * @async
   * @function handleResultClick
   * @returns {Promise<void>} A promise that resolves when the task is triggered and the response data is set.
   */
  const handleResultClick = async () => {
    try {
      const data = {
        latitude: position.lat,
        longitude: position.lng,
        address: address,
        heading: heading,
        pitch: pitch,
      };
      const response = await axios.post(
        "https://spe.orangelaboratory.com/foot/results/",
        data
      );
      setResponseData(response.data);
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };

  // Function to pass to Results component
  const triggerResultClick = () => {
    handleResultClick();
  };

  const handleAIClick = async () => {
    try {
      const data = {
        address: address,
        heading: heading,
        pitch: pitch,
      };
      const response = await axios.post(
        "http://209.20.159.56:9000/call/",
        data
      );
      console.log("New button clicked");
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };

/**
 * Handles the click event for the SAM button.
 * 
 * This function performs the following steps:
 * 1. Calls the `handleApply` function before opening the modal.
 * 2. Retrieves the current panorama details from the `panoramaRef`.
 * 3. Constructs a Street View image URL using the panorama details and a Google Maps API key.
 * 4. Sets the constructed image URL to the state and shows the modal.
 * 
 * @async
 * @function handleSamClick
 * @returns {Promise<void>} A promise that resolves when the function completes.
 * @throws Will log an error message to the console if an error occurs during execution.
 */
const handleSamClick = async () => {
  try {
    // Call handleApply before opening the modal
    await handleApply();

    if (panoramaRef.current) {
      // Get the current panorama details
      const pano = panoramaRef.current.getPano();
      const pov = panoramaRef.current.getPov();

      // Construct the Street View image URL
      const imageUrl = `https://maps.googleapis.com/maps/api/streetview?size=640x640&pano=${pano}&heading=${pov.heading}&pitch=${pov.pitch}&key=AIzaSyC9ijkEj5qtZdFD-c0umowBwF4w4IWUE4s`;
      console.log("In SAM2 clicked");
      setImageSrc(imageUrl);
      setModalShow(true);
    }
  } catch (error) {
    console.error("Error in SAM2 click:", error);
  }
};

/**
 * Clears the table by removing all dots from the coordinate history and right-click coordinate history,
 * and then resets the coordinate history, coordinates, right-click coordinates, and right-click coordinate history.
 */
const handleClearTable = () => {
  coordinateHistory.forEach((coord) => {
    if (coord.dot) {
      coord.dot.remove();
    }
  });
  rightClickCoordinateHistory.forEach((coord) => {
    if (coord.dot) {
      coord.dot.remove();
    }
  });

  setCoordinateHistory([]);
  setCoordinates([]);
  setRightClickCoordinates([]);
  setRightClickCoordinateHistory([]);
};


const handleImageClick = (event) => {
  const rect = event.target.getBoundingClientRect();

  // Directly calculate X and Y relative to the image without cursor offset
  const x = event.clientX - rect.left + 16;
  const y = event.clientY - rect.top + 16;

  if (event.button === 0) { // Left-click
    const newCoordinate = { x, y };

    // Append the new coordinate to the coordinates array
    setCoordinates((prevCoordinates) => [...prevCoordinates, newCoordinate]);

    // Create a dot element
    const dot = document.createElement('div');
    dot.style.position = 'absolute';
    dot.style.width = '3px';
    dot.style.height = '3px';
    dot.style.backgroundColor = 'green';
    dot.style.borderRadius = '50%';
    dot.style.left = `${x}px`;
    dot.style.top = `${y}px`;

    // Add the dot to the image container
    event.target.parentElement.appendChild(dot);

    // Store the dot element for later removal
    setCoordinateHistory((prevHistory) => {
      const updatedHistory = [...prevHistory, { ...newCoordinate, dot }];
      if (updatedHistory.length > 10) {
        updatedHistory.shift(); // Remove the oldest entry if more than 10
      }
      return updatedHistory;
    });

    console.log(`Left-click at X: ${newCoordinate.x}, Y: ${newCoordinate.y}`);
  } else if (event.button === 2) { // Right-click
    event.preventDefault(); // Prevent the default context menu from appearing
    console.log(`Right-click at X: ${x}, Y: ${y}`);
    const newCoordinate = { x, y };
    // Append the new coordinate to the rightClickCoordinates array
    setRightClickCoordinates((prevCoordinates) => [...prevCoordinates, newCoordinate]);

    // Create a dot element for right-click (e.g., in red)
    const dot = document.createElement('div');
    dot.style.position = 'absolute';
    dot.style.width = '3px';
    dot.style.height = '3px';
    dot.style.backgroundColor = 'red';
    dot.style.borderRadius = '50%';
    dot.style.left = `${x}px`;
    dot.style.top = `${y}px`;
    dot.style.pointerEvents = 'none';

    // Add the dot to the image container
    event.target.parentElement.appendChild(dot);
    setRightClickCoordinateHistory((prevHistory) => {
      const updatedHistory = [...prevHistory, { ...newCoordinate, dot }];
      if (updatedHistory.length > 10) {
        updatedHistory.shift();
      }
      return updatedHistory;
    });

    console.log(`Right-click at X: ${newCoordinate.x}, Y: ${newCoordinate.y}`);
  }
};

  // Add this function to prevent the context menu
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  
    
  /**
   * Handles the click event for updating the segment.
   * Updates the imageSrc state with a new URL containing a timestamp query parameter to prevent caching.
   */
  const handleSegmentUpdateClick = () => {
    // Update the imageSrc state to the new value
    const timestamp = new Date().getTime();
    setImageSrc(`https://spe.orangelaboratory.com/sam/image?timestamp=${timestamp}`);
  };

  /**
   * Handles the click event to reset the segment.
   * Updates the imageSrc state to the reset URL.
   */
  const handleSegmentResetClick = () => {
    // Update the imageSrc state to the new value
    setImageSrc("https://spe.orangelaboratory.com/sam/reset");
  };

  // Function to handle the Segment button click
  /**
   * Handles the click event for the segment button.
   * Sends a POST request with coordinates and right-click coordinates to the specified URL.
   * Logs the response and triggers the segment update click handler.
   * 
   * @async
   * @function handleSegmentClick
   * @returns {Promise<void>} - A promise that resolves when the function completes.
   * @throws {Error} - Throws an error if the POST request fails.
   */
  const handleSegmentClick = async () => {
    try {
      const data = {
        coordinates: coordinates, // Send the entire array of coordinates
        rightClickCoordinates: rightClickCoordinates,
      };
      console.log("Segment button clicked", data);
      const response = await axios.post(
        "https://spe.orangelaboratory.com/sam/",
        data
      );
      console.log("Segment button clicked", response);
      handleSegmentUpdateClick();
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };
  

  const handleMallClick = async () => {
    try {
      const data = {
        address: address,
        heading: heading,
        pitch: pitch,
      };
      const response = await axios.post(
        "http://209.20.159.56:9000/triton/mall/",
        data
      );
      console.log("Window button clicked");
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };

  const handleWindowClick = async () => {
    try {
      const data = {
        address: address,
        heading: heading,
        pitch: pitch,
      };
      const response = await axios.post(
        "http://209.20.159.56:9000/triton/windows/",
        data
      );
      console.log("Window button clicked");
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };
  const handleClear = () => {
    setAddress("");
    setInputWidth(300);
  };

  /**
   * Handles the change event for the address input field.
   * Updates the address state and adjusts the input field width dynamically.
   *
   * @param {Event} e - The change event triggered by the input field.
   */
  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    setInputWidth(Math.max(300, newAddress.length * 10));
  };

  /**
   * Handles the selection event on the map and updates the position state.
   *
   * @param {Object} event - The event object from the map.
   * @param {Object} event.latLng - The latitude and longitude object from the event.
   * @param {Function} event.latLng.lat - Function to get the latitude.
   * @param {Function} event.latLng.lng - Function to get the longitude.
   */
  const onSelect = (event) => {
    setPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  /**
   * Handles the event when the position of the Street View panorama changes.
   * Updates the position state if the new position is different from the current position.
   *
   * @function
   * @name onStreetViewPositionChanged
   * @returns {void}
   */
  const onStreetViewPositionChanged = () => {
    if (panoramaRef.current) {
      const newPosition = panoramaRef.current.getPosition();
      if (
        !position ||
        newPosition.lat() !== position.lat ||
        newPosition.lng() !== position.lng
      ) {
        setPosition({ lat: newPosition.lat(), lng: newPosition.lng() });
      }
    }
  };

  const onPovChanged = () => {
    if (panoramaRef.current) {
      const pov = panoramaRef.current.getPov();
      setHeading(pov.heading);
      setPitch(pov.pitch);
    }
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC9ijkEj5qtZdFD-c0umowBwF4w4IWUE4s">
      <div
        style={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2,
        }}
      >
        <Stack direction="horizontal" gap={2}>
          <Results
            resultData={responseData}
            onResultClick={triggerResultClick}
            imageUrl=""
            onUserComment={handleUserComment}
          />
          <Button
            variant="primary"
            onClick={handleSamClick}
            style={{
              fontSize: "24px",
              backgroundColor: "#F0512B",
              borderColor: "#F0512B",
            }}
          >
            SAM2
          </Button>

          {/* <Button
            variant="primary"
            onClick={handleMallClick}
            style={{
              fontSize: "24px",
              backgroundColor: "#F0512B",
              borderColor: "#F0512B",
            }}
          >
            Mall
          </Button> */}
          <Button
            variant="primary"
            onClick={handleAnalyzeClick}
            style={{
              fontSize: "24px",
              backgroundColor: "#F0512B",
              borderColor: "#F0512B",
            }}
          >
            Analyze
          </Button>
          {isPopupVisible && <Popup text={progressText} />}
          <Form.Control
            type="text"
            placeholder="Address & Submit"
            value={address}
            onChange={handleAddressChange}
            style={{ fontSize: "24px", width: `${inputWidth}px` }}
          />
          <Button
            variant="primary"
            onClick={handleGeocode}
            style={{
              fontSize: "24px",
              backgroundColor: "#F0512B",
              borderColor: "#F0512B",
            }}
          >
            Submit
          </Button>
          <Button
            variant="primary"
            onClick={handleClear}
            style={{
              fontSize: "24px",
              backgroundColor: "#F0512B",
              borderColor: "#F0512B",
            }}
          >
            Clear
          </Button>
        </Stack>
      </div>
      <div style={{ display: "flex" }}>
        <GoogleMap
          center={center}
          zoom={14}
          mapContainerStyle={{ width: "50vw", height: "100vh" }}
          onClick={onSelect}
        >
          {position && <Marker position={position} />}
          {position && (
            <OverlayView
              position={position}
              mapPaneName={OverlayView.OVERLAY_LAYER}
            >
              <div
                style={{
                  transform: `rotate(${heading}deg)`,
                  width: 0,
                  height: 0,
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  borderBottom: "30px solid blue",
                }}
              />
            </OverlayView>
          )}
        </GoogleMap>
        {position && (
          <div style={{ position: "relative", width: "50vw", height: "100vh" }}>
            <GoogleMap
              center={position}
              zoom={14}
              mapContainerStyle={{ width: "100%", height: "100%" }}
            >
              <StreetViewPanorama
                ref={panoramaRef}
                position={position}
                visible={true}
                onPositionChanged={onStreetViewPositionChanged}
                onPovChanged={onPovChanged}
                onLoad={(panorama) => (panoramaRef.current = panorama)}
              />
            </GoogleMap>
            <div
              style={{
                position: "absolute",
                bottom: 10,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "white",
                padding: "10px",
                zIndex: 1,
              }}
            >
              <div>Heading: {heading.toFixed(1)}</div>
              <div>Pitch: {pitch.toFixed(1)}</div>
            </div>
          </div>
        )}
      </div>

      <Modal show={modalShow} onHide={handleCloseModal} size="xl" centered>
  <Modal.Header closeButton>
    <Modal.Title>Segmentation Service - Left Click Add - Right Click Subtract</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: "20px" }}>
            <img
              src={imageSrc}
              alt="Street View"
              style={{ width: "640px", height: "640px", cursor: "crosshair" }}
              onClick={handleImageClick}
              onContextMenu={handleImageClick}
            />
          </div>
          <div style={{ flex: 1 }}>
            <h4>Positive Coordinates</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>X Coordinate</th>
                  <th>Y Coordinate</th>
                </tr>
              </thead>
              <tbody>
                {coordinateHistory.map((coord, index) => (
                  <tr key={index}>
                    <td>{coord.x}</td>
                    <td>{coord.y}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h4>Negative Coordinates</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>X Coordinate</th>
                  <th>Y Coordinate</th>
                </tr>
              </thead>
              <tbody>
                {rightClickCoordinates.map((coord, index) => (
                  <tr key={index}>
                    <td>{coord.x}</td>
                    <td>{coord.y}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Close
    </Button>
    <Button variant="danger" onClick={handleClearTable}>
      Clear
    </Button>
    <Button variant="primary" onClick={handleSegmentClick}>
      Segment
    </Button>
    {/* <Button variant="primary" onClick={handleSegmentUpdateClick}>
      Update
    </Button> */}
    <Button variant="danger" onClick={handleSegmentResetClick}>
      Reset
    </Button>
  </Modal.Footer>
</Modal>

    </LoadScript>
  );
};

export default FootPrintMap;

