import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import FootPrintMap from "./FootPrintMap";
import OffcanvasComponent from "./Offcanvas";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../../assets/logo.svg";
import fb from "../../assets/facebook.svg"; 
import insta from "../../assets/instagram.svg";
import twitter from "../../assets/twitter-x.svg";
import './RowStyles.css'
// Begin code for Home.js
export const Home = () => {
  const [name, setName] = useState("");
  const [navigate, setNavigate] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get("user");

        setName(data.name);
      } catch (e) {
        setNavigate(true);
      }
    })();
  }, []);

  const call = async () => {
    try {
      const { data } = await axios.get("call", {}, { withCredentials: true });
      setEmail(data.email);
    } catch (e) {
      setNavigate(true);
    }
  };

  const logout = async () => {
    await axios.post("logout", {}, { withCredentials: true });

    setNavigate(true);
  };

  if (navigate) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      {/* <h3>Hi {name} Email {email} </h3>
        <a href="javascript:void(0)" className="btn btn-lg btn-primary"
           onClick={call}>Call</a>
        <a href="javascript:void(0)" className="btn btn-lg btn-primary"
           onClick={logout}
        >Logout</a> */}
      <OffcanvasComponent />
      <main>
        <Container className="">
          <Row className="px-4 my-5">
            <Col sm={7}>
              <Image src="https://picsum.photos/id/742/900/400" rounded fluid />
            </Col>
            <Col sm={5}>
              <h1 class="font-weight-light fs-5">
                Property Evaluation, But Smart
              </h1>
              <p class="mt-1">
                We evaluate damaged structures. As construction experts, we
                provide independent recommendations and analyses for clients
                around the world in the insurance and legal industries. We have
                a national presence with offices from coast to coast and a
                robust set of specialized, in-house practices that range from
                scheduling and building envelope to mechanical, electrical,
                elevator, and landscaping. Hard evidence, expertise, and
                accuracy drive our work; communication and connection are our
                top priorities. Simply put, our work empowers our clients to
                move forward with confidence. In a complex industry, our mission
                is to bring clarity to every client we serve.
              </p>
              <Button variant="outline-primary" className="mt-1">
                Our Services
              </Button>
            </Col>
          </Row>
          <Row className="">
            <Card className="text-center bg-secondary text-white mb-5 py-4 fs-3">
              <Card.Body>
                Building Integrity, Coast to Coast: Expertise that Empowers
              </Card.Body>
            </Card>
          </Row>
          <Row className="">
            <Col>
              <Card style={{ width: "18rem",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
                <Card.Img
                  variant="top"
                  src="https://picsum.photos/id/43/320/200"
                />
                <Card.Body>
                  <Card.Title>Smart Property Evaluation System</Card.Title>
                  <Card.Text>
                    Our AI-driven solutions complement our national presence,
                    ensuring that from coast to coast, clients receive the most
                    advanced and reliable guidance in the construction sector.
                    We are committed to affirming our status as the foremost
                    construction experts who have paved the way for future
                    advancements in property evaluation today.
                  </Card.Text>
                  <Button variant="primary">Read more</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card style={{ width: "18rem",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)"  }}>
                <Card.Img
                  variant="top"
                  src="https://picsum.photos/id/238/320/200"
                />
                <Card.Body>
                  <Card.Title>Smart Property Evaluation System</Card.Title>
                  <Card.Text>
                    Our AI-driven solutions complement our national presence,
                    ensuring that from coast to coast, clients receive the most
                    advanced and reliable guidance in the construction sector.
                    We are committed to affirming our status as the foremost
                    construction experts who have paved the way for future
                    advancements in property evaluation today.
                  </Card.Text>
                  <Button variant="primary">Read more</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card style={{ width: "18rem",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
                <Card.Img
                  variant="top"
                  src="https://picsum.photos/id/299/320/200"
                />
                <Card.Body>
                  <Card.Title>Smart Property Evaluation System</Card.Title>
                  <Card.Text>
                    Our AI-driven solutions complement our national presence,
                    ensuring that from coast to coast, clients receive the most
                    advanced and reliable guidance in the construction sector.
                    We are committed to affirming our status as the foremost
                    construction experts who have paved the way for future
                    advancements in property evaluation today.
                  </Card.Text>
                  <Button variant="primary">Read more</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <footer
        className="my-5 py-5 bg-dark text-white text-center"
        style={{ position: "relative" }}
      >
        <div
          className="footer-icons"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "25px",
          }}
        >
          {/* Replace these img tags with your actual SVGs or SVG components */}
          <img src={twitter} alt="Twitter Icon" style={{ marginRight: '10px' }} />
          <img src={fb} alt="Facebook Icon" style={{ marginRight: '10px' }} />
          <img src={insta} alt="Instagram Icon" />
        </div>
        <Container className="px-4">
          <p className="text-center">
            Copyright &copy; DBI Consultants 2024 All rights reserved.
          </p>
        </Container>
      </footer>
    </div>
  );
};
