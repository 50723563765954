import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Login } from "./Components/Map/Login";
import { Register } from "./Components/Map/Register";
import { Home } from "./Components/Map/Home";
import logo from "./assets/logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import Property from "./Components/Map/Property";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { PrivateRoute } from "./contexts/PrivateRoute";
import { AuthContext } from "./contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";

function App() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [navigate, setNavigate] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get("user");

        setName(data.name);
      } catch (e) {
        setNavigate(true);
      }
    })();
  }, []);

  const logout = async () => {
    await axios.post("logout", {}, { withCredentials: true });
    setIsAuthenticated(false);
    return <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <header className="p-3 bg-dark text-white">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <li className="nav-link px-2 text-white d-flex align-items-center">
                {" "}
                {/* Added flexbox properties here */}
                <img
                  src={logo}
                  alt="DBI Logo"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginRight: "10px",
                  }}
                />
                <Link to="/" className="text-white">
                  DBI Smart Property Evaluation System
                </Link>
              </li>
            </ul>

            <div className="text-end">
              <Link to="/login" className="btn btn-outline-light me-2">
                Login
              </Link>
              <Link to="/register" className="btn btn-outline-light me-2">
                Register
              </Link>
              <button
                type="button"
                className="btn btn-outline-light me-2"
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/properties"
          element={
            <PrivateRoute>
              <Property />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
