import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LinkContainer } from "react-router-bootstrap";
import homeImage from "../../assets/home.svg";
import homeImage2 from "../../assets/house-fill.svg";
import propertyImage from "../../assets/property.svg";
import logoutImage from "../../assets/logout.svg";
import logoutImage2 from "../../assets/door-closed.svg";
import propertyImage2 from "../../assets/building-fill-gear.svg";
import logo from "../../assets/logo.svg";
import { Image } from "react-bootstrap";
import "./Offcanvas.css";
import aboutImage from "../../assets/about.svg";
import contactImage from "../../assets/contact.svg";
import sitemapImage from "../../assets/sitemap.svg";

const OffcanvasComponent = () => {
  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-white mb-3 mt-5"
        >
          <Container fluid>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              style={{
                backgroundColor: "white",
                borderColor: "#F0512B",
                color: "#F0512B",
              }}
            >
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              className="toggle"
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className="fs-4 d-flex flex-column align-items-start"
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{ marginBottom: "10px" }} // Adjust the space between the image and the text
                  />
                  <span>Smart Property Evaluation</span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body
                className="fs-3"
                style={{
                  backgroundColor: "#F0512B",
                  color: "white",
                  textEmphasis: "bold",
                  textShadow: "1px 1px 1px gray",
                }}
              >
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <LinkContainer to="/">
                    <Nav.Link className="d-flex align-items-center fs-5 nav-link-custom">
                      <Image src={homeImage2} alt="Home" className="me-2" />
                      Home
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/properties">
                    <Nav.Link className="d-flex align-items-center fs-5 nav-link-custom">
                      <Image
                        src={propertyImage2}
                        alt="property"
                        className="me-2"
                      />
                      Smart Property Evaluation
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <Nav.Link className="d-flex align-items-center fs-5 nav-link-custom">
                      <Image src={aboutImage} alt="about" className="me-2" />
                      About
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/contact">
                    <Nav.Link className="d-flex align-items-center fs-5 nav-link-custom">
                      <Image src={contactImage} alt="contact" className="me-2" />
                      Contact Us
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/sitemap">
                    <Nav.Link className="d-flex align-items-center fs-5 nav-link-custom">
                      <Image src={sitemapImage} alt="sitemap" className="me-2" />
                      Sitemap
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/signout">
                    <Nav.Link className="d-flex align-items-center fs-5 nav-link-custom">
                      <Image src={logoutImage2} alt="logout" className="me-2" />
                      Sign Out
                    </Nav.Link>
                  </LinkContainer>
                  <NavDropdown
                    className="fs-5"
                    title="Legal"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">About</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Terms & Conditions
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      Privacy Policy
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action6">Communities</NavDropdown.Item>
                    <NavDropdown.Item href="#action7">Commitment</NavDropdown.Item>
                    <NavDropdown.Item href="#action7">
                      Envronmental Policy
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default OffcanvasComponent;
