import {React, useEffect, useState}  from 'react';
import axios from 'axios';
import {Navigate} from "react-router-dom";
import FootPrintMap from './FootPrintMap';

const Property = () => {
    const [name, setName] = useState('');
    const [navigate, setNavigate] = useState(false);
    const [email, setEmail] = useState('')

    useEffect(() => {
        (async () => {
            try {
                const {data} = await axios.get('user');

                setName(data.name);
            } catch (e) {
                setNavigate(true);
            }
        })();
    }, []);

    if (navigate) {
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <FootPrintMap />
        </div>
    );
}

export default Property;
